import { Box, Checkbox, FormControl, IconButton } from "@mui/material";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
// import qrcode from "../resources/assets/images/qrcode.svg";
import loadingAnime from "../resources/assets/images/Spin-1s-200px.svg";
import line from "../resources/assets/images/line.svg";
import { useExchangeContext } from "../context/ExchangeContext";
import {
  usePrepareContractBatchWrite,
  useContractBatchWrite,
  usePrepareSendUserOperation,
  useSendUserOperation,
} from "@zerodev/wagmi";
import tokenAbi from "../resources/contracts/erc.json";
import { ethers } from "ethers";
import { BigNumber } from "ethers";
import { toast } from "react-toastify";
import { useSdkContext } from "../context/SdkContext";

const CompleteExchange = ({ changeVal }) => {
  const {
    payoutAddress,
    transactionId,
    tokenOne,
    tokenOneAmount,
    tokenTwo,
    tokenTwoAmount,
    paymentAddress,
  } = useExchangeContext();
  const [transactionStatus, setTransactionStatus] = useState(null);
  const { client } = useSdkContext();

  const fetchTransactionStatus = useCallback(async () => {
    const id = transactionId; // Replace with your actual transaction ID
    const apiKey = process.env.REACT_APP_CHANGE_NOW_API_KEY; // Replace with your actual API key
    const url = `https://api.changenow.io/v1/transactions/${id}/${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log("Transaction Status", data.status);
      if (data && data.status) {
        setTransactionStatus(data.status);
      }
    } catch (error) {
      console.error("Error fetching transaction status:", error);
    }
  }, [transactionId]);

  useEffect(() => {
    // Fetch the status immediately on mount, and then set up the interval
    fetchTransactionStatus();
    const intervalId = setInterval(fetchTransactionStatus, 5000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [fetchTransactionStatus]);

  const statusOrder = {
    waiting: 1,
    confirming: 2,
    exchanging: 3,
    sending: 4,
    finished: 5, // Assuming there's a finished state
  };

  const renderStatus = (stage) => {
    const currentStatusValue = statusOrder[transactionStatus];
    const stageValue = statusOrder[stage];

    if (currentStatusValue > stageValue) {
      // If the current status is past this stage, show the checkmark
      return (
        <>
          ✅<div>{stage.charAt(0).toUpperCase() + stage.slice(1)}</div>
        </>
      );
    } else if (currentStatusValue === stageValue) {
      // If the current status is this stage, show the loading animation
      return (
        <>
          <img src={loadingAnime} alt="loading..." style={{ height: "55px" }} />
          {stage === "waiting" ? (
            <div>Awaiting Deposit</div>
          ) : (
            <div>{stage.charAt(0).toUpperCase() + stage.slice(1)}</div>
          )}
        </>
      );
    } else {
      // If the current status has not reached this stage, show a default icon or nothing
      return (
        <>
          {/* Placeholder for incomplete stages, could be an empty icon or just a blank space */}
          <img src={loadingAnime} alt="loading..." style={{ height: "55px" }} />
          <div>{stage.charAt(0).toUpperCase() + stage.slice(1)}</div>
        </>
      );
    }
  };

  // console.log(
  //   "Payout Address",
  //   payoutAddress,
  //   transactionId,
  //   tokenOne.ticker,
  //   BigNumber.from(tokenOneAmount)
  //     .mul(BigNumber.from(10).pow(tokenOne.decimals))
  //     .toString()
  // );

  // const { config: config1, refetch: refetch1 } = usePrepareSendUserOperation({
  //   to: payoutAddress,
  //   value: ethers.utils.parseEther(tokenOneAmount),
  //   data: "0x",
  // });

  // const { sendUserOperation: sendToken, data: data1 } =
  //   useSendUserOperation(config1);

  // const interval2 = useRef();

  // const handleSendETH = useCallback(() => {
  //   console.log("Sending eth...", data1);
  //   if (sendToken) {
  //     sendToken();
  //     interval2.current = setInterval(() => {
  //       refetch1();
  //     }, 1000);
  //     setTimeout(() => {
  //       if (interval2.current) {
  //         clearInterval(interval2.current);
  //       }
  //     }, 100000);
  //   }
  // }, [sendToken, refetch1]);

  // const {
  //   config: config2,
  //   refetch: refetch2,
  //   data: data2,
  // } = usePrepareContractBatchWrite({
  //   calls:
  //     tokenOne.ticker == "eth" || !payoutAddress
  //       ? []
  //       : [
  //           {
  //             address: tokenOne.tokenAddress,
  //             abi: tokenAbi, // Make sure this ABI is compatible with the token you're sending
  //             functionName: "transfer",
  //             args: [
  //               payoutAddress,
  //               BigNumber.from(tokenOneAmount)
  //                 .mul(BigNumber.from(10).pow(tokenOne.decimals))
  //                 .toString(),
  //             ],
  //           },
  //         ],
  //   enabled: true,
  // });

  // console.log("Config2", config2);

  // const { sendUserOperation: batchSendToken, data } =
  //   useContractBatchWrite(config2);

  // const interval = useRef();

  // const handleSendERC20 = useCallback(() => {
  //   console.log("Sending token...", data2);
  //   if (batchSendToken) {
  //     batchSendToken();
  //     interval.current = setInterval(() => {
  //       refetch2();
  //     }, 1000);
  //     setTimeout(() => {
  //       if (interval.current) {
  //         clearInterval(interval.current);
  //       }
  //     }, 100000);
  //   }
  // }, [batchSendToken, refetch2]);

  const handleSendETH = async () => {
    const transaction = {
      target: payoutAddress,
      data: "0x",
      value: ethers.utils.parseEther(tokenOneAmount),
    };
    try {
      const { userOpHash } = await client.sendUserOp(transaction);
      console.log("UserOp Hash:", userOpHash);
      console.log("Transaction sent successfully");
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const handleSendERC20 = async () => {
    const functionInterface = new ethers.utils.Interface(tokenAbi);

    const data = functionInterface.encodeFunctionData("transfer", [
      payoutAddress,
      BigNumber.from(tokenOneAmount)
        .mul(BigNumber.from(10).pow(tokenOne.decimals))
        .toString(),
    ]);

    const transaction = {
      target: tokenOne.tokenAddress, // Replace with the recipient's Ethereum address
      data: data, // Replace with the transaction data in hexadecimal format
    };

    try {
      const { userOpHash } = await client.sendUserOp(transaction);
      console.log("UserOp Hash:", userOpHash);
      console.log("Transaction sent successfully");
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "2A2A2A",
        maxWidth: "40rem",
        width: "95%",
        boxShadow: "0px 0px 20px 1px black",
        p: "2rem",
      }}
    >
      <Box
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "white",
          mb: "2rem",
        }}
      >
        Please send the funds you would like to exchange
      </Box>
      <Box
        sx={{
          minHeight: "4rem",
          display: "flex",
          alignItems: "center",
          m: "1rem 0",
          p: "2rem",
          bgcolor: "#292929",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <div style={{ fontSize: "12px" }}>Amount</div>
          <div
            style={{
              fontSize: "20px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            {tokenOneAmount} {tokenOne.ticker.toUpperCase()}
          </div>
          <div style={{ fontSize: "10px" }}>To this address</div>
          <Box
            sx={{
              fontSize: "10px",
              fontWeight: "bold",
              wordWrap: "break-word",
            }}
          >
            {payoutAddress}
          </Box>
          <Box sx={{ m: "5px 0", fontSize: "12px" }}>
            <button
              style={{
                width: "100%",
                height: "3rem",
                backgroundColor: "#9747FF",
                fontWeight: "bold",
                cursor: "pointer",
                margin: "2rem 0",
                borderRadius: "10px",
              }}
              onClick={() => {
                tokenOne.ticker === "eth" ? handleSendETH() : handleSendERC20();
              }}
            >
              Transfer
            </button>
          </Box>
        </Box>
        {/* <Box>
          <img src={qrcode} alt="QR Code" style={{ height: "5rem" }} />
        </Box> */}
      </Box>
      <Box
        sx={{
          borderBottom: "1px solid #626262",
          minHeight: "4rem",
          display: "flex",
          p: "0rem 0 1rem 0",
          m: "2rem 0",
        }}
      >
        <Box sx={{ width: "90%" }}>
          <div style={{ fontSize: "12px" }}>You get</div>
          <div
            style={{
              fontSize: "20px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            ≈ {tokenTwoAmount} {tokenTwo.ticker.toUpperCase()}
          </div>
          <div style={{ fontSize: "10px", paddingTop: "12px" }}>
            Recepient Wallet
          </div>
          <div
            style={{ fontSize: "10px", paddingBottom: "12px", color: "white" }}
          >
            {paymentAddress}
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          fontSize: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "flex-start",
            height: "6rem",
          }}
        >
          {renderStatus("waiting")}
        </Box>
        <img src={line} alt="---" style={{ width: "35px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "flex-start",
            height: "6rem",
          }}
        >
          {renderStatus("confirming")}
        </Box>
        <img src={line} alt="---" style={{ width: "35px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "flex-start",
            height: "6rem",
          }}
        >
          {renderStatus("exchanging")}
        </Box>
        <img src={line} alt="---" style={{ width: "35px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "flex-start",
            height: "6rem",
          }}
        >
          {renderStatus("sending")}
        </Box>
      </Box>
    </Box>
  );
};

export default CompleteExchange;
