import "@rainbow-me/rainbowkit/styles.css";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";
import ZeroDevWrapper from "./ZeroDevWrapper";
import { NotificationsProvider } from "@mantine/notifications";
import ExchangeContextProvider from "./context/ExchangeContext";
import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  localWallet,
  embeddedWallet,
  trustWallet,
  bloctoWallet,
  phantomWallet,
} from "@thirdweb-dev/react";
import { Ethereum } from "@thirdweb-dev/chains";
import { SdkProvider } from "./context/SdkContext";
import { MoonPayProvider } from "@moonpay/moonpay-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <SdkProvider>
      <MoonPayProvider apiKey="pk_test_key" debug>
        <ThirdwebProvider
          activeChain={Ethereum}
          clientId="6ce6d2ca70d9ad276906372f2ba55d79"
          supportedWallets={[metamaskWallet(), walletConnect(), trustWallet()]}
        >
          <MantineProvider
            theme={{ colorScheme: "dark" }}
            withGlobalStyles
            withNormalizeCSS
          >
            <NotificationsProvider>
              <ZeroDevWrapper>
                <ExchangeContextProvider>
                  <App />
                </ExchangeContextProvider>
              </ZeroDevWrapper>
            </NotificationsProvider>
          </MantineProvider>
        </ThirdwebProvider>
      </MoonPayProvider>
    </SdkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
