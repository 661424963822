import React, { createContext, useContext, useEffect, useState } from "react";
import exchangeTokenList1 from "../utils/exchangeTokenList1.json";
import exchangeTokenList2 from "../utils/exchangeTokenList2.json";

const ExchangeContext = createContext();
const ExchangeContextProvider = ({ children }) => {
  const [changeToken, setChangeToken] = useState(1);
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tokenOne, setTokenOne] = useState(
    //@ts-ignore
    exchangeTokenList1[0]
  );
  const [tokenTwo, setTokenTwo] = useState(
    //@ts-ignore
    exchangeTokenList2[0]
  );
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [paymentAddress, setPaymentAddress] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [payoutAddress, setPayoutAddress] = useState("");

  const fetchExchangeAmount = async (sendAmount) => {
    if (!sendAmount || !tokenOne.ticker || !tokenTwo.ticker) return;

    const from_to = `${tokenOne.ticker}_${tokenTwo.ticker}`;
    //@ts-ignore
    const api_key = process.env.REACT_APP_CHANGE_NOW_API_KEY; // Replace with your actual API key
    console.log("API KEY", api_key, sendAmount);
    const url = `https://api.changenow.io/v1/exchange-amount/fixed-rate/${sendAmount}/${from_to}?api_key=${api_key}&useRateId=true`;
    console.log(url);

    const response = await fetch(url);
    if (response.status !== 200) {
      const data = await response.json();
      console.log("Error123", data.error);
      setError(data.error);
      return;
    }
    const data = await response.json();

    console.log(data.estimatedAmount);
    setError(null);

    if (data.estimatedAmount) {
      setTokenTwoAmount(data.estimatedAmount);
    }
  };

  const createTransaction = async () => {
    const api_key = process.env.REACT_APP_CHANGE_NOW_API_KEY;
    const url = `https://api.changenow.io/v1/transactions/${api_key}`; // Replace XXX with your actual API key

    const headers = {
      "Content-Type": "application/json",
    };

    console.log(
      "createTransaction",
      tokenOne.ticker,
      tokenTwo.ticker,
      paymentAddress,
      tokenOneAmount
    );

    const body = {
      from: tokenOne.ticker,
      to: tokenTwo.ticker,
      address: paymentAddress,
      amount: tokenOneAmount,
      extraId: "",
      userId: "",
      contactEmail: "",
      refundAddress: "",
      refundExtraId: "",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Transaction Data", data);
      console.log("Data Id", data.id, data.payinAddress);
      setPayoutAddress(data.payinAddress);
      setTransactionId(data.id);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  function openModal(asset) {
    setChangeToken(asset);
    setIsOpen(true);
  }

  function modifyTokenOne(i) {
    //setPrices(null);
    console.log("modifyTokenOne");
    setTokenOneAmount("");
    setTokenTwoAmount("");
    if (changeToken === 1) {
      //@ts-ignore
      setTokenOne(exchangeTokenList1[i]);
      //fetchPrices(tokenList[i].address, tokenTwo.address);
    }
    setIsOpen(false);
  }

  function modifyTokenTwo(i) {
    console.log("modifyTokenTwo");
    setTokenOneAmount("");
    setTokenTwoAmount("");
    if (changeToken === 2) {
      // Check if the changeToken is set to 2 for token two
      setTokenTwo(exchangeTokenList2[i]); // Update tokenTwo state
    }
    setIsOpen(false);
  }

  useEffect(() => {
    let timer;
    const handleInput = async () => {
      if (tokenOneAmount === "") {
        setTokenTwoAmount(null);
      } else {
        fetchExchangeAmount(tokenOneAmount);
      }
    };
    timer = setTimeout(handleInput, 1000);
    return () => clearTimeout(timer);
    //@ts-ignore
  }, [tokenOneAmount]);

  return (
    <>
      <ExchangeContext.Provider
        value={{
          changeToken,
          tokenOneAmount,
          tokenTwoAmount,
          isOpen,
          tokenOne,
          tokenTwo,
          error,
          paymentAddress,
          payoutAddress,
          transactionId,
          setPaymentAddress,
          openModal,
          modifyTokenOne,
          modifyTokenTwo,
          setIsOpen,
          setTokenOneAmount,
          setChangeToken,
          setTokenOne,
          setTokenTwo,
          fetchExchangeAmount,
          createTransaction,
        }}
      >
        {children}
      </ExchangeContext.Provider>
    </>
  );
};
export const useExchangeContext = () => useContext(ExchangeContext);

export default ExchangeContextProvider;
