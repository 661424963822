import { useEffect, useState, useRef } from "react";
import {
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  ScrollArea,
} from "@mantine/core";
import { ReactComponent as ZeroDevLogo } from "./resources/assets/images/logo.svg";
import { useMediaQuery } from "@mantine/hooks";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Link, useLocation } from "react-router-dom";
import { useAccount } from "wagmi";
import { Login } from "./Login";
import { ZeroDevWeb3Auth } from "@zerodev/web3auth";
import { useSdkContext } from "./context/SdkContext";
import { getEllipsisTxt } from "./utils/formatters";

const useStyles = createStyles((theme, _params) => {
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      justifyContent: "center",
      width: "100%",
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.md,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },
  };
});

export interface DashboardProps {
  children: React.ReactNode;
  links: { path: string; label: string }[];
}

export function Dashboard({ children, links }: DashboardProps) {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);
  const mdMatches = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
  const [opened, setOpened] = useState(false);
  const { pathname } = useLocation();
  const {
    isConnected,
    smartWalletAddress,
    setIsOpen,
    chains,
    handleSelectChain,
    activeChain,
    isOpen,
  } = useSdkContext();

  const [copied, setCopied] = useState(false);

  const dropdownRef = useRef(null);

  // Handle click outside of dropdown to close
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const copyToClipboard = (str: string) => {
    navigator.clipboard.writeText(str).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  // useEffect(() => {
  //   if (isConnected) {
  //     const zeroDevWeb3Auth = new ZeroDevWeb3Auth([
  //       process.env.REACT_APP_ZERODEV_PROJECT_ID ||
  //         "8ff3621f-95bd-4b17-93ad-4c0cf10adc37",
  //     ]);
  //     zeroDevWeb3Auth.getUserInfo().then(console.log);
  //   }
  // }, [isConnected]);

  // if (!isConnected) {
  //   return <Login />;
  // }

  if (!isConnected) {
    return <Login></Login>;
  }

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
          bg="black"
        >
          <Navbar.Section grow mt="md" component={ScrollArea}>
            {matches ? (
              <MediaQuery
                largerThan={"sm"}
                styles={{ paddingLeft: 20, paddingRight: 20 }}
              >
                <Group className={classes.header} position="apart">
                  <ZeroDevLogo width={"100%"} />
                </Group>
              </MediaQuery>
            ) : null}
            {links.map((item) => (
              <Link
                className={cx(classes.link, {
                  [classes.linkActive]: item.path === pathname,
                })}
                to={item.path}
                key={item.label}
                onClick={() => {
                  setOpened(false);
                }}
              >
                <span>{item.label}</span>
              </Link>
            ))}
          </Navbar.Section>
          <div className="relative inline-block text-left w-40" ref={dropdownRef}>
            <div>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {activeChain}
              </button>
            </div>

            {/* Use `top-0 mt-1` to move the dropdown above the button */}
            <div
              className={`${
                isOpen ? "block" : "hidden"
              } absolute bottom-10 right-0 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 mt-1`}
            >
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {Object.keys(chains).map((chainName) => (
                  <a
                    href="#"
                    key={chainName}
                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${
                      activeChain === chainName ? "bg-gray-200" : ""
                    }`}
                    role="menuitem"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSelectChain(chainName);
                    }}
                  >
                    {chainName}
                  </a>
                ))}
              </div>
            </div>
          </div>
          {
            <Navbar.Section
              className={classes.footer}
              style={{
                justifyContent: "center",
              }}
            >
              {/* <ConnectButton showBalance={mdMatches || !matches} /> */}
              <span className="text-lg font-bold">
                {getEllipsisTxt(smartWalletAddress, 6)}
              </span>
              <button onClick={() => copyToClipboard(smartWalletAddress)}>
                <span className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-2 ml-8 rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Copy
                </span>
              </button>
            </Navbar.Section>
          }
        </Navbar>
      }
      header={
        matches ? undefined : (
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Header height={{ base: 50, md: 70 }} p="md">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  height: "100%",
                }}
              >
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="sm"
                />

                <ZeroDevLogo height={30} width={116} />
              </div>
            </Header>
          </MediaQuery>
        )
      }
    >
      {children}
    </AppShell>
  );
}
