import { Box, Checkbox, FormControl, IconButton } from "@mui/material";
import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useExchangeContext } from "../context/ExchangeContext";

const ConfirmDetails = ({ changeVal }) => {
  const handleChangePage = () => changeVal(1);
  const { tokenOneAmount, tokenTwoAmount, paymentAddress, tokenOne, tokenTwo, createTransaction, payoutAddress, transactionId } =
    useExchangeContext();

  return (
    <Box
      sx={{
        bgcolor: "2A2A2A",
        maxWidth: "30rem",
        width: "95%",
        boxShadow: "0px 0px 20px 1px black",
        p: "2rem",
      }}
    >
      <Box
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "white",
          mb: "2rem",
        }}
      >
        Please Confirm your exchange details
      </Box>
      <Box
        sx={{
          borderBottom: "1px solid #626262",
          minHeight: "4rem",
          display: "flex",
          m: "1rem 0",
        }}
      >
        <Box sx={{ width: "90%" }}>
          <div style={{ fontSize: "12px" }}>You send</div>
          <div
            style={{
              fontSize: "20px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            {tokenOneAmount} {tokenOne.ticker.toUpperCase()}
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          borderBottom: "1px solid #626262",
          minHeight: "4rem",
          display: "flex",
          pt: "1rem",
          m: "1rem 0",
        }}
      >
        <Box sx={{ width: "90%" }}>
          <div style={{ fontSize: "12px" }}>You get</div>
          <div
            style={{
              fontSize: "20px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            ≈ {tokenTwoAmount} {tokenTwo.ticker.toUpperCase()}
          </div>
          <div style={{ fontSize: "10px", paddingBottom: "12px" }}>
            {paymentAddress}
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          borderBottom: "1px solid #626262",
          minHeight: "4rem",
          pt: "1rem",
          m: "1rem 0",
        }}
      >
        <Box sx={{ width: "90%" }}>
          <div style={{ fontSize: "12px" }}>Estimated Arrival</div>
          <div style={{ fontSize: "12px" }}> ≈ 10 minutes</div>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <button
          style={{
            width: "50%",
            height: "3rem",
            border: "1px solid #9747FF",
            fontWeight: "bold",
            cursor: "pointer",
            margin: "2rem 0",
            borderRadius: "10px",
            margin: "5px",
          }}
          onClick={() => changeVal(0)}
        >
          BACK
        </button>
        <button
          style={{
            width: "50%",
            height: "3rem",
            backgroundColor: "#9747FF",
            fontWeight: "bold",
            cursor: "pointer",
            margin: "2rem 0",
            borderRadius: "10px",
            margin: "5px",
          }}
          onClick={async () => {
            await createTransaction()
            console.log("Next Button", payoutAddress, transactionId)
            changeVal(2);
          }}
        >
          NEXT
        </button>
      </Box>
    </Box>
  );
};

export default ConfirmDetails;
