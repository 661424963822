import React, { useEffect } from "react";
import {
  createStyles,
  Title,
  Text,
  Container,
  Flex,
  Button,
  Image,
} from "@mantine/core";
// import { ReactComponent as ZeroDevLogo } from "./resources/assets/images/logo.png";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Passkey from "./Passkey";
import { TypeAnimation } from "react-type-animation";
import { useSdkContext } from "./context/SdkContext";
import ReactDOM from "react-dom";
import TelegramLoginButton from "./Components/TelegramLoginButton";

// API KEY = 0xGS-38447a1b1dd5f056ff9ec7893cac3ffca5732743bae32216031e2f0734dc9e1a

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",

    "@media (max-width: 755px)": {
      paddingTop: 80,
      paddingBottom: 60,
    },
  },

  title: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: 40,
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `DM Mono, monospace,${theme.fontFamily}`,

    "@media (max-width: 520px)": {
      fontSize: 28,
    },
  },

  highlight: {
    color: "linear-gradient(127deg,#1e1e1e, #642f7b,#35004b )",
  },
}));

export function Login() {
  const { classes } = useStyles();
  const demoProps = {
    bg: "url('/background.svg')",
    h: "100vh",
    w: "100%",
  };

  const { connect, handleTelegramResponse } = useSdkContext();

  //@ts-ignore

  return (
    <>
      <Flex
        style={{
          padding: "4%",
          textAlign: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover", // You can adjust this to control the image size
          backgroundPosition: "center", // You can adjust this to control the image position
        }}
        {...demoProps}
      >
        <Flex
          justify={"center"}
          align="center"
          mih={"100%"}
          w={"100%"}
          // bg="black"
          direction={"column"}
          gap={30}
        >
          <Image width={300} height={"100%"} src="/logo.png" />
          {/* <ZeroDevLogo width={300} height={"100%"} /> */}
          <Title className={classes.title}>
            0xgasless smart wallets with
            <br />
            {/* <Text component="span" className={classes.highlight} inherit>
            Account Abstraction
          </Text> */}
            <TypeAnimation
              className={classes.highlight}
              sequence={[
                "Account Abstraction",
                1000, // wait 1s before replacing "Mice" with "Hamsters"
                "Transaction Batching",
                1000,
                "Social Recovery",
                1000,
                "Gasless Transactions",
                1000,
              ]}
              wrapper="span"
              speed={20}
              style={{
                fontSize: "28px",
                color: " linear-gradient(127deg,#1e1e1e, #642f7b,#35004b )",
                display: "inline-block",
              }}
              repeat={Infinity}
            />
          </Title>
          {/* <ConnectButton label={"Start"} /> */}
          <div className="flex flex-row justify-center items-center gap-8">
            <button
              onClick={connect}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Connect Wallet
            </button>
            <div id="telegram-login">
              <TelegramLoginButton
                dataOnauth={handleTelegramResponse}
                botName="Oxgasless_test_bot"
                language="en"
              />
            </div>

            {/* <button
            onClick={handleTelegramClick}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            Login with Telegram
          </button> */}
          </div>
          0xgasless will create an AA wallet for you using social account or
          metamask.
        </Flex>
      </Flex>
    </>
  );
}
