import React, { useState, useEffect, useRef, useCallback } from "react";
import { Input, Popover, Radio, Modal, message } from "antd";

import {
  ArrowDownOutlined,
  DownOutlined,
  SettingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import tokenList from "./utils/tokenList.json";
import axios from "axios";
import { TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import {
  useAccount,
  usePublicClient,
  useWalletClient,
  useWaitForTransaction,
} from "wagmi";
import { BigNumber, ethers } from "ethers";
// import { Biconomy } from "@biconomy/mexa";
import { tokenABI, routerABI } from "./utils/abi.js";
import apiList from "./utils/apiList.json";
import {
  SignTypedDataVersion,
  recoverTypedSignature,
} from "@metamask/eth-sig-util";
import { getEthersProvider } from "./utils/provider";
import { EntryPointAbi } from "./utils/entrypoint.js";
import {
  usePrepareContractBatchWrite,
  useContractBatchWrite,
} from "@zerodev/wagmi";
import { themeVars } from "@rainbow-me/rainbowkit/dist/css/sprinkles.css";
// import { activeChainId } from "./utils/chainConfig";
import { useSdkContext } from "./context/SdkContext";

function AASwap(props: any) {
  // const { address, isConnected } = useAccount();
  const { smartWalletAddress, isConnected, client } = useSdkContext();
  let activeChainId = 1;
  // const { data: provider } = useWalletClient();
  const { provider } = useSdkContext();
  const [messageApi, contextHolder] = message.useMessage();
  const [slippage, setSlippage] = useState(2.5);
  const [tokenOneAmount, setTokenOneAmount] = useState<string | null>(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [route, setRoute] = useState<any>(null);

  //@ts-ignore
  const chainName = "ethereum";

  //@ts-ignore
  const [tokenOne, setTokenOne] = useState(
    //@ts-ignore
    tokenList[1][0]
  );
  const [tokenTwo, setTokenTwo] = useState(
    //@ts-ignore
    tokenList[1][1]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [changeToken, setChangeToken] = useState(1);
  // const { state: walletState, wallet } = useSmartAccountContext();

  const [isLoading, setIsLoading] = useState(false);
  const [destination, setDestination] = useState(false);
  const [estimatedGas, setEstimatedGas] = useState("0");
  const [ethPrice, setEthPrice] = useState("0.81");

  const [swBalanceOne, setSwBalanceOne] = useState("0");
  const [swBalanceTwo, setSwBalanceTwo] = useState("0");
  const [gasPrice, setGasPrice] = useState("0");
  const [gasAmount, setGasAmount] = useState("0");
  const [txs, setTxs] = useState([]);
  const [id, setId] = useState();
  const [swapToggled, setSwapToggled] = useState(false);
  const [gasPriceUsd, setGasPriceUsd] = useState(0);
  const [tokenInfo, setTokenInfo] = useState(null);
  const [invalidAddress, setInvalidAddress] = useState(null);
  const [pairAddress, setPairAddress] = useState();

  // //ZERODEV
  // const {
  //   config,
  //   refetch,
  //   data: data2,
  // } = usePrepareContractBatchWrite({
  //   calls: txs,

  //   enabled: true,
  // });

  // const { sendUserOperation: batchSwapToken, data } =
  //   useContractBatchWrite(config);

  const interval = useRef<any>();

  async function getDexPairs() {
    const newAddress = tokenTwo.address.toLowerCase();
    console.log(tokenTwo.address, newAddress);
    const url = `https://api.gopluslabs.io/api/v1/token_security/1?contract_addresses=${newAddress}`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data.result, newAddress);
      const newPairAddress = data.result[newAddress].dex[0].pair;
      console.log("Pair Address", newPairAddress);
      setPairAddress(newPairAddress);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  useEffect(() => {
    console.log("Token Two Changed...", tokenTwo);
    getDexPairs();
  }, [tokenTwo]);

  // const handleSwapToken = useCallback(() => {
  //   console.log("Swapping token...", data2);
  //   try {
  //     if (batchSwapToken) {
  //       console.log("Entered batchSwapToken...");
  //       batchSwapToken();
  //       interval.current = setInterval(() => {
  //         refetch();
  //       }, 1000);
  //       setTimeout(() => {
  //         if (interval.current) {
  //           clearInterval(interval.current);
  //         }
  //       }, 100000);
  //     }
  //   } catch (error) {
  //     toast.dismiss(id);
  //     console.log(error);
  //   }
  // }, [batchSwapToken, refetch]);

  // useWaitForTransaction({
  //   hash: data?.hash,
  //   enabled: !!data,
  //   async onSuccess() {
  //     console.log("txMined event received via emitter", data?.hash);
  //     setIsLoading(false);
  //     setTokenOneAmount(null);
  //     setTokenTwoAmount(null);
  //     toast.dismiss(id);
  //     setId(undefined);
  //     setTxs([]);
  //     setRoute(null);
  //     toast("Transaction Successful", {
  //       type: "success",
  //       position: "bottom-left",
  //       autoClose: 5000,
  //       isLoading: false,
  //       icon: "👏",
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //   },
  // });
  // //ZERODEV

  // useEffect(() => {
  //   if (txs.length >= 2) {
  //     refetch();
  //     console.log("Entered 3rd if", txs);
  //     handleSwapToken();
  //   }
  // }, [txs]);

  // useEffect(() => {
  //   //@ts-ignore
  //   if (data2 && swapToggled && data2.data.length > 0) {
  //     // Now that data2 is available, you can call handleSwapToken
  //     handleSwapToken();
  //   }
  // }, [data2, handleSwapToken, swapToggled]);

  useEffect(() => {
    let intervalId: any;
    async function fetchBalances() {
      try {
        if (isConnected) {
          setTimeout(async () => {
            const swTokenBalanceOne = smartWalletAddress
              ? await getBalance(
                  smartWalletAddress,
                  tokenOne.address,

                  tokenOne.decimals
                )
              : "0";

            // console.log("Getting Balance", tokenOne.address, swTokenBalanceOne);

            const swTokenBalanceTwo = smartWalletAddress
              ? await getBalance(
                  smartWalletAddress,
                  tokenTwo.address,

                  tokenTwo.decimals
                )
              : "0";

            // console.log("Getting Balance", tokenTwo.address, swTokenBalanceTwo);

            // assuming setBalanceOne and setBalanceTwo are the state setters for balances of the tokens.

            setSwBalanceOne(swTokenBalanceOne);
            setSwBalanceTwo(swTokenBalanceTwo);
          }, 500); //500
        }
      } catch (e) {
        console.log("error bal", e);
      }
    }

    if (smartWalletAddress && provider) {
      console.log("Fetching Balances");
      fetchBalances();
      intervalId = setInterval(() => {
        fetchBalances();
      }, 5000); //5000
    }
    return () => clearInterval(intervalId);
  }, [tokenOne, tokenTwo, isConnected, provider]);

  useEffect(() => {
    let timer: any;
    const handleInput = async () => {
      if (tokenOneAmount === null) return;
      const tokenOut = await fetchQuote(
        tokenOne,
        tokenTwo,
        //@ts-ignore
        ethers.utils.parseUnits(tokenOneAmount, tokenOne.decimals)
      );
      console.log(
        tokenOut,
        tokenTwo,
        (Number(tokenOut) / 10 ** tokenTwo.decimals).toFixed(3),
        "hehe"
      );
      if (tokenOneAmount && tokenOut) {
        const tokenTwoAmount = (
          Number(tokenOut) /
          10 ** tokenTwo.decimals
        ).toFixed(3);
        setTokenTwoAmount(tokenTwoAmount);
        console.log("Hello", tokenOneAmount);
      }
    };
    timer = setTimeout(handleInput, 1000);
    return () => clearTimeout(timer);
    //@ts-ignore
  }, [tokenOneAmount]);

  function handleSlippageChange(e: any) {
    setSlippage(e.target.value);
  }

  function handleDestination(e: any) {
    setDestination(e.target.value);
  }

  // function to wait untill userOp broadcast and suceed.
  const isUserOpSucceed = (
    hash: string,
    beforeTxnBlockNumber: number
  ): Promise<boolean> => {
    const UserOperationEventTopic =
      "0x49628fd1471006c1482da88028e9ce4dbb080b815c9b0344d39e5a8e6ec1419f";
    const entrypointAddress = "0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789";

    // need to change rpc for arbitrum
    const rpcUrl =
      "https://polygon-mainnet.g.alchemy.com/v2/u8CS-Au9S1UhjRELXbYRKrkDoIaiqQzb";
    const staticProvider = new ethers.providers.StaticJsonRpcProvider(rpcUrl);

    // const blockDifference = 50; // for polygon
    return new Promise(async (resolve, reject) => {
      let index = 0;

      const requestParams = {
        fromBlock: beforeTxnBlockNumber,
        toBlock: "latest",
        address: entrypointAddress,
        topics: [
          UserOperationEventTopic, // correspond to userOperationEvent
        ],
      };

      while (1) {
        const logs = await staticProvider.send("eth_getLogs", [requestParams]);
        let filteredLogs = [];
        // topics[1] -> userOpHash
        //@ts-ignore
        filteredLogs = logs.filter((log) => log.topics[1] === hash);
        if (filteredLogs.length > 0) {
          resolve(true);
          break;
        }
        index++;
        if (index > 100) {
          resolve(false);
          break;
        }
      }
    });
  };

  //@ts-ignore
  async function getBalance(address, tokenContractAddress, decimals) {
    const provider = getEthersProvider({
      chainId: activeChainId,
    });
    if (tokenContractAddress === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") {
      const balance = await provider.getBalance(address);

      return ethers.utils.formatUnits(balance, 18);
    } else {
      const contract = new ethers.Contract(
        tokenContractAddress,
        ["function balanceOf(address owner) view returns (uint256)"],
        provider
      );
      const balance = await contract.balanceOf(address);
      // console.log(
      //   "balance",
      //   balance,
      //   ethers.utils.formatUnits(balance, Number(decimals))
      // );
      return ethers.utils.formatUnits(balance, Number(decimals));
    }
  }

  async function checkApproval(
    ownerAddress: string,
    spenderAddress: string,
    tokenContractAddress: string
  ) {

    // If the given token address is the special placeholder for ETH, then approval is not applicable.
    if (tokenContractAddress === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") {
      return "Not Applicable for ETH";
    } else {
      const contract = new ethers.Contract(
        tokenContractAddress,
        [
          "function allowance(address owner, address spender) view returns (uint256)",
        ],
        provider
      );

      const approvedAmount = await contract.allowance(
        ownerAddress,
        spenderAddress
      );
      return approvedAmount; // Assuming 18 decimals but this can be adjusted.
    }
  }

  const domainType = [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "verifyingContract", type: "address" },
    { name: "salt", type: "bytes32" },
  ];

  const metaTransactionType = [
    { name: "nonce", type: "uint256" },
    { name: "from", type: "address" },
    { name: "functionSignature", type: "bytes" },
  ];

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async function changeAmount(e: any, token: any = undefined) {
    try {
      const amount = token ? token : e.target.value;
      setTokenOneAmount(amount);
    } catch (e) {
      setTokenTwoAmount(null);
      console.log(e);
    }
  }
  function switchTokens() {
    //setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    const one = tokenOne;
    const two = tokenTwo;
    setTokenOne(two);
    setTokenTwo(one);
    //fetchPrices(two.address, one.address);
  }

  function openModal(asset: any) {
    setChangeToken(asset);
    setIsOpen(true);
  }

  function modifyToken(i: any) {
    //setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    if (changeToken === 1) {
      //@ts-ignore
      setTokenOne(tokenList[1][i]);
      //fetchPrices(tokenList[i].address, tokenTwo.address);
    } else {
      //@ts-ignore
      setTokenTwo(tokenList[1][i]);
      //fetchPrices(tokenOne.address, tokenList[i].address);
    }
    setIsOpen(false);
  }

  /*   useEffect(() => {
    async function getEthPrice() {
      const res = await axios.get(`https://api.coincap.io/v2/rates/polygon`);
   
      setEthPrice(res.data.data.rateUsd);
    }

    getEthPrice();
  }, []); */

  function modifyToken2(i: any) {
    //setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    if (changeToken === 1) {
      //@ts-ignore
      setTokenOne({
        //@ts-ignore
        ticker: tokenInfo.symbol,
        img: "https://cdn-icons-png.flaticon.com/512/10840/10840849.png",
        //@ts-ignore
        name: tokenInfo.name,
        //@ts-ignore
        address: tokenInfo.address,
        //@ts-ignore
        decimals: tokenInfo.decimals,
      });
      //fetchPrices(tokenList[i].address, tokenTwo.address);
    } else {
      //@ts-ignore
      setTokenTwo({
        //@ts-ignore
        ticker: tokenInfo.symbol,
        img: "https://cdn-icons-png.flaticon.com/512/10840/10840849.png",
        //@ts-ignore
        name: tokenInfo.name,
        //@ts-ignore
        address: tokenInfo.address,
        //@ts-ignore
        decimals: tokenInfo.decimals,
      });
      //fetchPrices(tokenOne.address, tokenList[i].address);
    }
    setIsOpen(false);
  }

  /*   useEffect(() => {
    async function getEthPrice() {
      const res = await axios.get(`https://api.coincap.io/v2/rates/polygon`);
   
      setEthPrice(res.data.data.rateUsd);
    }

    getEthPrice();
  }, []); */
  //@ts-ignore
  async function fetchQuote(one, two, tokenOneAmount) {
    try {
      const ress = await axios.get(
        `https://aggregator-api.kyberswap.com/${chainName}/api/v1/routes`,
        {
          params: {
            tokenIn: one.address,
            tokenOut: two.address,
            amountIn: tokenOneAmount,
          },
        }
      );
      console.log(ress.data.data, "kyberGas");
      const gasUsd = parseFloat(ress.data.data.routeSummary.gasUsd);
      console.log(gasUsd, "gasUsd");
      const estGas = gasUsd + (20 / 100) * gasUsd;
      const estGasPrice = parseFloat(estGas.toFixed(2));
      setGasPriceUsd(estGasPrice);

      const actualGas = estGas * 10 ** 6;

      console.log(actualGas, "actualGas");

      if (
        one.address == "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" &&
        tokenOneAmount.lte(BigNumber.from(actualGas.toFixed(0)))
      ) {
        console.log("lte called");
        setErrorMsg("Not enough balance to pay for gas");
        return "0";
      }

      // 25% add karke 10 pow 6 se multiply
      console.log("I am here");
      const gasAmountt =
        one.address == "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
          ? actualGas.toFixed(0)
          : await fetchQuoteGas(one, actualGas.toFixed(0));

      if (BigNumber.from(gasAmountt).gt(tokenOneAmount)) {
        setErrorMsg("Not enough balance to pay for gas");
        return "0";
      }

      tokenOneAmount = BigNumber.from(tokenOneAmount).sub(gasAmountt);

      setGasAmount(gasAmountt);
      console.log("Entered fetchQuote", gasAmountt);

      /*console.log(one.address, two.address, tokenOneAmount.toString());
      
      const res = await axios.get(
        `https://api.1inch.io/v5.0/${activeChainId}/quote/`,
        {
          params: {
            fromTokenAddress: one.address,
            toTokenAddress: two.address,
            amount: tokenOneAmount.toString(),
          },
        }
      );
      console.log('resp');*/

      const res = await axios.get(
        `https://aggregator-api.kyberswap.com/${chainName}/api/v1/routes`,
        {
          params: {
            tokenIn: one.address,
            tokenOut: two.address,
            amountIn: tokenOneAmount.toString(),
            saveGas: false,
            gasInclude: true,
          },
        }
      );
      setRoute(res.data.data.routeSummary);
      setEstimatedGas(res.data.data.routeSummary.gas);
      setErrorMsg("");
      return res.data.data.routeSummary.amountOut;
    } catch (error: any) {
      if (error.response?.data.statusCode == 400) {
        setErrorMsg("Something went wrong");
      } else {
        setErrorMsg("");
      }
    }
  }

  //@ts-ignore
  async function fetchQuoteGas(token, actualGas) {
    try {
      /*const res = await axios.get(
        `https://api.1inch.io/v5.0/${activeChainId}/quote/`,
        {
          params: {
            fromTokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            toTokenAddress: token.address,
            amount: "15000000",
          },
        }
      );
      console.log(res.data);*/
      console.log("Entered fetchQuoteGas", actualGas, token);
      if (token.address == "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48") {
        return actualGas;
      }
      const res = await axios.get(
        `https://aggregator-api.kyberswap.com/${chainName}/api/v1/routes`,
        {
          params: {
            tokenIn: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            tokenOut: token.address,
            amountIn: actualGas,
          },
        }
      );
      console.log(res.data.data.routeSummary.gas, "kyberGas");
      return res.data.data.routeSummary.amountOut;
    } catch (error: any) {
      console.log(error);
    }
  }

  async function createApprovalData(
    tokenAddress: string,
    spenderAddress: string,
    amount: string
  ) {
    // Convert the amount from its decimal representation to a raw token unit
    /*     const rawAmount = ethers.utils
      .parseUnits(amount.toString(), decimals)
      .toString(); */

    // Generate the ABI encoded data for the approval function
    const approvalData = ethers.utils.defaultAbiCoder.encode(
      ["address", "uint256"],
      [spenderAddress, amount]
    );

    // The function selector for the `approve` function in ERC20 is `0x095ea7b3`
    const data = "0x095ea7b3" + approvalData.slice(2);

    return {
      to: tokenAddress,
      data: data,
    };
  }

  const getSignatureParameters = (signature: string) => {
    if (!ethers.utils.isHexString(signature)) {
      throw new Error(
        'Given value "'.concat(signature, '" is not a valid hex string.')
      );
    }
    var r = signature.slice(0, 66);
    var s = "0x".concat(signature.slice(66, 130));
    var v: any = "0x".concat(signature.slice(130, 132));
    v = ethers.BigNumber.from(v).toNumber();
    if (![27, 28].includes(v)) v += 27;
    return {
      r: r,
      s: s,
      v: v,
    };
  };

  const fetchTokenInfo = async (address: any) => {
    console.log("fetchTokenInfo called", address);
    if (address.length == 0) {
      setTokenInfo(null);
      setInvalidAddress(null);
      return;
    }
    try {
      const provider = getEthersProvider({
        chainId: 1,
      });

      // Use ethers to fetch token information
      const tokenContract = new ethers.Contract(
        address,
        tokenABI.abi,
        provider
      );
      const name = await tokenContract.name();
      const symbol = await tokenContract.symbol();
      const decimals = await tokenContract.decimals();

      // Set the token information in state
      console.log(name, symbol, address, decimals);
      //@ts-ignore
      setTokenInfo({ name, symbol, address, decimals });
      setInvalidAddress(null); // Clear any previous invalid address message
    } catch (error) {
      console.error(error);
      setTokenInfo(null); // Clear any previous token info
      //@ts-ignore
      setInvalidAddress("Invalid address");
      // Set the invalid address message
    }
  };

  const settings = (
    <>
      <div>Slippage Tolerance</div>
      <div style={{ marginBottom: "15px" }}>
        <Radio.Group value={slippage} onChange={handleSlippageChange}>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={2.5}>2.5%</Radio.Button>
          <Radio.Button value={5}>5.0%</Radio.Button>
          <Radio.Button value={25}>25.0%</Radio.Button>
        </Radio.Group>
      </div>
      {/* <div>Swap to main Address?</div>
      <div>
        <Radio.Group value={destination} onChange={handleDestination}>
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </div> */}
    </>
  );

  function setPrice() {
    changeAmount(undefined, Number(parseFloat(swBalanceOne)).toString());
  }

  async function fetchDexSwap() {
    if (
      !tokenOneAmount ||
      !isConnected ||
      parseFloat(tokenOneAmount) > parseFloat(swBalanceOne) ||
      !smartWalletAddress
    )
      return;
    const idd = toast.loading("⏳ Preparing Swap transaction...", {
      theme: "dark",
      position: "bottom-left",
    });
    //@ts-ignore
    setId(idd);
    try {
      setSwapToggled(true);
      console.log("Step 1");
      setIsLoading(true);
      if (parseFloat(swBalanceOne) >= parseFloat(tokenOneAmount)) {
        let txss = [];
        console.log("Entered 1st if");
        toast.update(idd, {
          render: "Getting Exchange Rate...",
          type: "info",
          isLoading: true,
          theme: "dark",
          icon: "⏳",
        });
        const allowance = await checkApproval(
          smartWalletAddress,
          "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
          tokenOne.address
        );

        console.log(allowance, "allowance");

        if (
          tokenOne.address !== "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" &&
          Number(allowance) <
            //@ts-ignore
            Number(parseFloat(tokenOneAmount * 10 ** tokenOne.decimals))
        ) {
          console.log("Entered 2nd if");
          const approve = await createApprovalData(
            tokenOne.address,
            "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
            ethers.utils
              .parseUnits(tokenOneAmount, tokenOne.decimals)
              //TODO: Uncomment this later
              .sub(BigNumber.from(gasAmount))
              .toString()
          );

          console.log(
            "Transaction 1",
            tokenOne.address,
            "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
            gasAmount,
            ethers.utils
              .parseUnits(tokenOneAmount, tokenOne.decimals)
              .sub(BigNumber.from(gasAmount))
              .toString()
          );

          const functionInterface1 = new ethers.utils.Interface(tokenABI.abi);
          const data1 = functionInterface1.encodeFunctionData("approve", [
            "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
            ethers.utils
              .parseUnits(tokenOneAmount, tokenOne.decimals)
              //TODO: Uncomment this later
              .sub(BigNumber.from(gasAmount))
              .toString(),
          ]);

          const tx1 = {
            target: approve.to, // Replace with the recipient's Ethereum address
            data: data1, // Replace with the transaction data in hexadecimal format
          };

          // const tx1 = {
          //   address: approve.to,
          //   abi: tokenABI.abi,
          //   functionName: "approve",
          //   args: [
          //     "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
          //     ethers.utils
          //       .parseUnits(tokenOneAmount, tokenOne.decimals)
          //       .sub(BigNumber.from(gasAmount))
          //       .toString(),
          //   ],
          // };

          console.log("tx1", tx1);
          //@ts-ignore
          // setTxs([...txs, tx1]);
          txss.push(tx1);
        }

        const functionInterface2 = new ethers.utils.Interface(tokenABI.abi);
        const data2 = functionInterface2.encodeFunctionData("transfer", [
          "0x715F45c4Fe4F72Cb75D6de2F36D0428923a70946",
          gasAmount,
        ]);

        console.log(
          "Transaction 2",
          "0x715F45c4Fe4F72Cb75D6de2F36D0428923a70946",
          gasAmount
        );

        const tx2 = {
          target: tokenOne.address, // Replace with the recipient's Ethereum address
          data: data2, // Replace with the transaction data in hexadecimal format
        };

        console.log("tx2", tx2);

        //@ts-ignore
        //TODO: Uncomment this later
        setTxs([...txs, tx2]);
        txss.push(tx2);

        const kyberSwapEndpoint = `https://aggregator-api.kyberswap.com/${chainName}/api/v1/route/build`;

        console.log("route", route);

        //@ts-ignore
        const kyberSwapPayload = {
          routeSummary: route, // you mentioned this is saved in a state variable
          deadline: Date.now() + 30 * 60 * 60 * 1000, // you might need to adjust this value based on your requirements
          slippageTolerance: 1000,

          recipient: smartWalletAddress,
          //TODO: ask about above recipient
        };

        const tx = await (
          await fetch(kyberSwapEndpoint, {
            method: "POST",

            body: JSON.stringify(kyberSwapPayload),
          })
        ).json();

        console.log("tx Data", tx.data);

        // x-api-key

        const contractInterface = new ethers.utils.Interface(routerABI);
        const encodedData = tx.data.data;
        const decodedData = contractInterface.parseTransaction({
          data: encodedData,
        });

        const functionInterface3 = new ethers.utils.Interface(routerABI);
        const data3 = functionInterface3.encodeFunctionData(
          decodedData.name,
          decodedData.args
        );

        console.log("Transaction 3", decodedData.name, decodedData.args);

        const tx3 = {
          target: tx.data.routerAddress, // Replace with the recipient's Ethereum address
          data: data3, // Replace with the transaction data in hexadecimal format
        };

        // const tx3 = {
        //   address: tx.data.routerAddress,
        //   abi: routerABI,
        //   functionName: decodedData.name,
        //   args: decodedData.args,
        // };

        console.log("tx3", tx3);

        //@ts-ignore
        // setTxs([...txs, tx3]);
        txss.push(tx3);

        console.log(txss);

        if (txss.length >= 2) {
          toast.update(idd, {
            render: "Waiting for Transaction...",
            type: "info",
            isLoading: true,
            theme: "dark",
            icon: "⏳",
          });
          //@ts-ignore
          setTxs(txss);
          try {
            const { userOpHash } = await client.sendUserOpsBatch(txss);
            console.log("UserOp Hash:", userOpHash);
            console.log("Transaction sent successfully");
          } catch (error) {
            console.error("Transaction failed:", error);
          }
        }
      }
    } catch (error: any) {
      console.log(error);
      toast.dismiss(idd);
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
        title="Select a token"
        className="modal"
      >
        <i>
          <SearchOutlined style={{ fontSize: "150%" }} />
        </i>
        <input
          type="text"
          id="fname"
          className="address"
          placeholder="Paste Address"
          onChange={(e) => fetchTokenInfo(e.target.value)}
        />

        <div className="modalContent">
          {/* @ts-ignore */}
          {tokenInfo ? (
            <div
              className="tokenChoice"
              onClick={() => modifyToken2(tokenInfo)}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/10840/10840849.png"
                alt="Token"
                className="tokenLogo"
              />
              {/* Omit the image */}
              <div className="tokenChoiceNames">
                <div className="tokenName">
                  {
                    //@ts-ignore
                    tokenInfo.name
                  }
                </div>
                <div className="tokenTicker">
                  {
                    //@ts-ignore
                    tokenInfo.symbol
                  }
                </div>
              </div>
            </div>
          ) : invalidAddress ? (
            <div className="invalidAddress" style={{ marginLeft: "20px" }}>
              The address you entered was {invalidAddress}
            </div>
          ) : (
            tokenList[1].map((e, i) => {
              return (
                <div
                  className="tokenChoice"
                  key={i}
                  onClick={() => modifyToken(i)}
                >
                  <img src={e.img} alt={e.ticker} className="tokenLogo" />
                  <div className="tokenChoiceNames">
                    <div className="tokenName">{e.name}</div>
                    <div className="tokenTicker">{e.ticker}</div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Modal>

      {/* <div id="dexscreener-embed">
        <iframe src="https://dexscreener.com/ethereum/0x28bB9bF9b8D7C8591dDf059628B0419063Db38a5?embed=1&theme=dark&trades=0&info=0"></iframe>
      </div> */}

      <div
        className="mobile-column-direction"
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          className="mobile-graph"
          style={{ width: "500px", height: "450px", marginTop: "40px" }}
        >
          {/* <iframe
            id="dextools-widget"
            title="DEXTools Trading Chart"
          
            src={`https://www.dextools.io/widget-chart/en/ether/pe-dark/${
              pairAddress
                ? pairAddress
                : "0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d"
            }?theme=light&chartType=2&chartResolution=30&drawingToolbars=false`}
          ></iframe> */}
          <iframe
            className="mobile-graph"
            id="dextools-widget"
            title="DEXTools Trading Chart"
            width="500"
            height="450"
            src={`https://www.dextools.io/widget-chart/en/ether/pe-light/${
              pairAddress
                ? pairAddress
                : "0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d"
            }?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false`}
          ></iframe>
        </div>

        <div className="tradeBox">
          <div className="tradeBoxHeader">
            <h4>Gasless swap</h4>
            <Popover
              content={settings}
              title="Settings"
              trigger="click"
              placement="bottomRight"
            >
              <SettingOutlined className="cog" />
            </Popover>
          </div>
          <div className="inputs">
            <Input
              inputMode="decimal"
              type="number"
              d
              backgroundColor="#212121"
              placeholder="0"
              // @ts-ignore
              value={tokenOneAmount}
              onChange={changeAmount}
              //disabled={!prices}
            />
            <Input
              backgroundColor="#212121"
              style={{
                backgroundColor: "#212121",
              }}
              placeholder="0"
              // @ts-ignore
              value={tokenTwoAmount}
              disabled={true}
            />
            <div className="switchButton" onClick={switchTokens}>
              <ArrowDownOutlined className="switchArrow" />
            </div>
            <div className="assetOne" onClick={() => openModal(1)}>
              <img
                src={tokenOne.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {tokenOne.ticker}

              <DownOutlined />
            </div>
            {isConnected && (
              <div className="assetOneBalance">
                Balance: {Number(parseFloat(swBalanceOne)).toFixed(3)}
                <div className="max" onClick={setPrice}>
                  MAX
                </div>
              </div>
            )}
            <div className="assetTwo" onClick={() => openModal(2)}>
              <img
                src={tokenTwo.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {tokenTwo.ticker}
              <DownOutlined />
            </div>
            {isConnected && (
              <div className="assetTwoBalance">
                Balance: {Number(parseFloat(swBalanceTwo)).toFixed(3)}
              </div>
            )}
          </div>
          {
            // @ts-ignore
            tokenOneAmount > 0 && (
              <p
                style={{
                  color: "white",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "medium",
                  fontSize: "14px",
                }}
              >
                Estimated Gas in USD : {gasPriceUsd}
              </p>
            )
          }
          {activeChainId == 1 && (
            <p
              style={{
                color: "white",
                marginTop: "10px",
                marginBottom: "10px",
                fontWeight: "medium",
                fontSize: "14px",
              }}
            >
              Gas will be charged in token being swapped.
            </p>
          )}
          {errorMsg && (
            <span style={{ fontWeight: "bold", color: "red" }}>{errorMsg}</span>
          )}
          <div
            className="swapButton"
            // @ts-ignore
            // disabled={
            //   errorMsg ||
            //   isLoading ||
            //   !tokenOneAmount ||
            //   !isConnected ||
            //   parseFloat(tokenOneAmount) > parseFloat(swBalanceOne)
            // }
            onClick={() => {
              console.log("Clicked");
              fetchDexSwap();
            }}
          >
            Swap
          </div>
        </div>
      </div>
    </>
  );
}

export default AASwap;
