import React from "react";
import { WagmiConfig, configureChains, createConfig, mainnet } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import {
  connectorsForWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  googleWallet,
  enhanceWalletWithAAConnector,
} from "@zerodev/wagmi/rainbowkit";

import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";

export const projectId = "8ff3621f-95bd-4b17-93ad-4c0cf10adc37";

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [infuraProvider({ apiKey: "6c3a8a6d8cd443d6bf1df175abac2bb4" })]
);

const connectors = connectorsForWallets([
  {
    groupName: "Login Options",

    wallets: [
      enhanceWalletWithAAConnector(metaMaskWallet({ chains, projectId }), {
        projectId: projectId,
        bundlerProvider: "PIMLICO",
        onlySendSponsoredTransaction: true,
      }),
      googleWallet({ chains, options: { projectId } }),
    ],
  },
]);

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

function ZeroDevWrapper({ children }: { children: React.ReactNode }) {
  return (
    <WagmiConfig config={config}>
      <RainbowKitProvider
        theme={darkTheme()}
        chains={chains}
        modalSize="compact"
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default ZeroDevWrapper;
