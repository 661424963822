import { Box, FormControl, IconButton } from "@mui/material";
import React, { useState, useContext } from "react";
import tokenList from "../utils/tokenList.json";
import exchangeTokenList1 from "../utils/exchangeTokenList1.json";
import exchangeTokenList2 from "../utils/exchangeTokenList2.json";
import { DownOutlined } from "@ant-design/icons";
import { Input, Popover, Radio, Modal, message } from "antd";
import {
  ArrowDownOutlined,
  SettingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { BigNumber, ethers } from "ethers";
import { tokenABI, routerABI } from "../utils/abi.js";
import { getEthersProvider } from "../utils/provider";
import { useExchangeContext } from "../context/ExchangeContext";

const ExchangeDetails = ({ changeVal }) => {
  const handleChangePage = () => changeVal(1);
  const {
    changeToken,
    isOpen,
    tokenOne,
    tokenTwo,
    openModal,
    modifyTokenOne,
    modifyTokenTwo,
    setIsOpen,
    error,
    tokenOneAmount,
    setTokenOneAmount,
    tokenTwoAmount,
    paymentAddress,
    setPaymentAddress
  } = useExchangeContext();

  return (
    <>
      <Modal
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
        title="Select a token"
        className="modal"
      >
        <div className="modalContent">
          {/* @ts-ignore */}
          {changeToken === 1
            ? exchangeTokenList1.map((e, i) => {
                return (
                  <div
                    className="tokenChoice"
                    key={i}
                    onClick={() => modifyTokenOne(i)}
                  >
                    <img src={e.image} alt={e.ticker} className="tokenLogo" />
                    <div className="tokenChoiceNames">
                      <div className="tokenName">{e.name}</div>
                      <div className="tokenTicker">{e.ticker}</div>
                    </div>
                  </div>
                );
              })
            : exchangeTokenList2.map((e, i) => {
                return (
                  <div
                    className="tokenChoice"
                    key={i}
                    onClick={() => modifyTokenTwo(i)}
                  >
                    <img src={e.image} alt={e.ticker} className="tokenLogo" />
                    <div className="tokenChoiceNames">
                      <div className="tokenName">{e.name}</div>
                      <div className="tokenTicker">{e.ticker}</div>
                    </div>
                  </div>
                );
              })}
        </div>
      </Modal>
      <Box
        sx={{
          height: "80%",
          bgcolor: "2A2A2A",
          maxWidth: "30rem",
          width: "95%",
          boxShadow: "0px 0px 20px 1px black",
          p: "2rem",
        }}
      >
        <Box
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "white",
            mb: "2rem",
          }}
        >
          Please fill in transaction details
        </Box>
        <Box
          sx={{
            border: "1px solid #626262",
            height: "4rem",
            borderRadius: "3px",
            bgcolor: "#2A2A2A",
            display: "flex",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ width: "90%", pl: "12px" }}>
            <div style={{ fontSize: "12px" }}>You send</div>
            <input
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                fontSize: "20px",
              }}
              type="number"
              value={tokenOneAmount}
              onChange={(e) => {
                setTokenOneAmount(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ width: "50%", position: "relative" }}>
            <div
              style={{ top: "0.45rem", right: '0.35rem', height: '3rem', borderRadius: '10px', padding: '0 8px' }}
              className="assetOne"
              onClick={() => openModal(1)}
            >
              <img
                src={tokenOne.image}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {tokenOne.ticker.toUpperCase()}

              <DownOutlined />
            </div>
          </Box>
        </Box>
        <div style={{ fontSize: "12px", margin: "1rem 0 1rem 12px" }}>
          {error && <span style={{ color: "red" }}>Error: {error}</span>}
          <br></br>
        </div>
        <Box
          sx={{
            border: "1px solid #626262",
            height: "4rem",
            borderRadius: "3px",
            bgcolor: "#2A2A2A",
            display: "flex",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ width: "90%", pl: "12px" }}>
            <div style={{ fontSize: "12px" }}>You Recieve</div>
            <span
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                fontSize: "20px",
              }}
            >
              {tokenTwoAmount ? tokenTwoAmount : "0"}
            </span>
          </Box>
          <Box sx={{ width: "50%", position: "relative"}}>
            <div
              style={{ top: "0.45rem", right: '0.35rem', height: '3rem', borderRadius: '10px', padding: '0 15px' }}
              className="assetTwo"
              onClick={() => openModal(2)}
            >
              <img
                src={tokenTwo.image}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {tokenTwo.ticker.toUpperCase()}

              <DownOutlined />
            </div>
          </Box>
        </Box>
        <Box>
          <Box>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                margin: "1rem 0 0.2rem 0",
              }}
            >
              Recipient Wallet
            </div>
          </Box>
          <input
            placeholder="Enter the Payment Address"
            style={{
              border: "1px solid #626262",
              height: "4rem",
              borderRadius: "3px",
              backgroundColor: "#2A2A2A",
              width: "100%",
              display: "flex",
              outline: "none",
              fontSize: "15px",
              padding: "0 12px",
              borderRadius: "10px",
            }}
            type="text"
            value={paymentAddress}
            onChange={(e) => {
              setPaymentAddress(e.target.value);
            }}
          />
        </Box>
        <button
          style={{
            width: "100%",
            height: "3rem",
            backgroundColor: "#9747FF",
            fontWeight: "bold",
            cursor: "pointer",
            margin: "2rem 0",
            borderRadius: "10px",
          }}
          onClick={handleChangePage}
          disabled={
            error || tokenOneAmount === "" || !tokenOneAmount || !tokenTwoAmount || !paymentAddress
          }
        >
          NEXT
        </button>
      </Box>
    </>
  );
};

export default ExchangeDetails;
