import { useEffect, useState, useCallback, useRef } from "react";
import { useAccount, useNetwork, useWaitForTransaction } from "wagmi";
import {
  usePrepareContractBatchWrite,
  useContractBatchWrite,
  usePrepareSendUserOperation,
  useSendUserOperation,
} from "@zerodev/wagmi";
import {
  Button,
  Flex,
  Modal,
  TextInput,
  Paper,
  Col,
  Text,
} from "@mantine/core";
import { Page } from "../Page";
import { BalanceItem, Client } from "@covalenthq/client-sdk";
import tokenAbi from "../resources/contracts/erc.json";
import axios from "axios";
import { BigNumber, ethers } from "ethers";
import { parseEther } from "viem";
import { useSdkContext } from "../context/SdkContext";
// import {
//   BatchUserOperationCallData,
//   sendUserOp,
// } from "@0xgasless/0xgasless-aa-sdk";

const description = `View your token portfolio and send tokens.`;

export function BatchExample() {
  const [tokenData, setTokenData] = useState<BalanceItem[]>([]);
  const [selectedToken, setSelectedToken] = useState<BalanceItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [amountToSend, setAmountToSend] = useState("0");
  const [gas, setGas] = useState("0");
  const [disabled, setDisabled] = useState(false);
  const { smartWalletAddress, provider, client, chainId } = useSdkContext();
  const fetchData = async () => {
    const client = new Client("cqt_rQjqjFgwBX8t9Mc3qhb4VBYrHgtj");
    const resp = await client.BalanceService.getTokenBalancesForWalletAddress(
      chainId === 1
        ? "eth-mainnet"
        : chainId === 250
        ? "fantom-mainnet"
        : chainId === 1284
        ? "moonbeam-mainnet"
        : "eth-mainnet",
      smartWalletAddress,
      {}
    );
    console.log(resp.data.items);
    setTokenData(resp.data.items);
  };

  useEffect(() => {
    console.log("Chain changed");
    fetchData();
  }, [smartWalletAddress, chainId]);

  async function fetchQuoteGas() {
    try {
      /*const res = await axios.get(
        `https://api.1inch.io/v5.0/${activeChainId}/quote/`,
        {
          params: {
            fromTokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            toTokenAddress: token.address,
            amount: "15000000",
          },
        }
      );
      console.log(res.data);*/
      if (
        selectedToken?.contract_address ==
        "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
      ) {
        return "10000000";
      }
      const res = await axios.get(
        `https://aggregator-api.kyberswap.com/ethereum/api/v1/routes`,
        {
          params: {
            tokenIn: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            tokenOut: selectedToken?.contract_address,
            amountIn: "10000000",
          },
        }
      );
      console.log(res.data.data.routeSummary.gas, "kyberGas");
      return res.data.data.routeSummary.amountOut;
    } catch (error: any) {
      console.log(error);
    }
  }

  // const { config: config1, refetch: refetch1 } = usePrepareSendUserOperation({
  //   to: recipientAddress,
  //   value:
  //     selectedToken == null || recipientAddress == "" || amountToSend == "0"
  //       ? BigInt(0)
  //       : parseEther(amountToSend),
  //   data: "0x",
  // });

  // const { sendUserOperation: sendToken, data: data1 } =
  //   useSendUserOperation(config1);

  // const {
  //   config,
  //   refetch,
  //   data: data2,
  // } = usePrepareContractBatchWrite({
  //   calls:
  //     selectedToken == null ||
  //     recipientAddress == "" ||
  //     gas == "0" ||
  //     amountToSend == "0" ||
  //     selectedToken?.contract_name.toLowerCase() == "ether"
  //       ? []
  //       : ethers.utils
  //           .parseUnits(amountToSend, selectedToken?.contract_decimals)
  //           .gt(BigNumber.from(selectedToken?.balance?.toString() as string)) ||
  //         BigNumber.from(gas).gt(
  //           ethers.utils.parseUnits(
  //             amountToSend,
  //             selectedToken?.contract_decimals
  //           )
  //         )
  //       ? []
  //       : [
  //           {
  //             address: selectedToken?.contract_address as string,
  //             abi: tokenAbi, // Make sure this ABI is compatible with the token you're sending
  //             functionName: "transfer",
  //             args: ["0x715F45c4Fe4F72Cb75D6de2F36D0428923a70946", gas],
  //           },
  //           {
  //             address: selectedToken?.contract_address as string,
  //             abi: tokenAbi, // Make sure this ABI is compatible with the token you're sending
  //             functionName: "transfer",
  //             args: [
  //               recipientAddress,
  //               ethers.utils
  //                 .parseUnits(amountToSend, selectedToken?.contract_decimals)
  //                 .sub(BigNumber.from(gas))
  //                 .toString(),
  //             ],
  //           },
  //         ],
  //   enabled: true,
  // });

  // const { sendUserOperation: batchSendToken, data } =
  //   useContractBatchWrite(config);

  const handleTokenClick = (token: BalanceItem) => {
    setSelectedToken(token);
    setIsModalOpen(true);
  };
  // const interval = useRef<any>();
  // const interval2 = useRef<any>();

  // const handleSendToken = useCallback(() => {
  //   if (batchSendToken) {
  //     setLoading(true);
  //     batchSendToken();
  //     interval.current = setInterval(() => {
  //       refetch();
  //     }, 1000);
  //     setTimeout(() => {
  //       if (interval.current) {
  //         clearInterval(interval.current);
  //       }
  //     }, 100000);
  //   }
  // }, [batchSendToken, refetch]);

  // const handleSendETH = useCallback(() => {
  //   console.log("Sending eth...", data1);
  //   if (sendToken) {
  //     setLoading(true);
  //     sendToken();
  //     interval2.current = setInterval(() => {
  //       refetch1();
  //     }, 1000);
  //     setTimeout(() => {
  //       if (interval2.current) {
  //         clearInterval(interval2.current);
  //       }
  //     }, 100000);
  //   }
  // }, [sendToken, refetch1]);

  // useWaitForTransaction({
  //   hash: data1?.hash,
  //   enabled: !!data1,
  //   async onSuccess() {
  //     await fetchData();
  //     setLoading(false);
  //     setIsModalOpen(false);
  //     setDisabled(false);
  //     setRecipientAddress("");
  //     setAmountToSend("0");
  //     setGas("0");
  //     alert("Transaction was successful.");
  //     console.log("Transaction was successful.", data?.hash);
  //   },
  // });

  // useWaitForTransaction({
  //   hash: data?.hash,
  //   enabled: !!data,
  //   async onSuccess() {
  //     await fetchData();
  //     setLoading(false);
  //     setIsModalOpen(false);
  //     setDisabled(false);
  //     setRecipientAddress("");
  //     setAmountToSend("0");
  //     setGas("0");
  //     alert("Transaction was successful.");
  //     console.log("Transaction was successful.", data?.hash);
  //   },
  // });

  const sendTokenTransaction = async () => {
    if (!selectedToken || !recipientAddress || amountToSend === "0") return;

    let transactions = [];

    if (selectedToken.contract_name.toLowerCase() === "ether") {
      console.log("Sending Ether");
      setLoading(true);
      // Send ETH
      const transaction1 = {
        target: recipientAddress,
        data: "0x",
        value: parseEther(amountToSend),
      };
      try {
        const { userOpHash } = await client.sendUserOp(transaction1);
        console.log("UserOp Hash:", userOpHash);
        console.log("Transaction sent successfully");
      } catch (error) {
        console.error("Transaction failed:", error);
      }
    } else {
      console.log("Sending ERC20");
      console.log("Client", client);
      // Send ERC20 token

      //TODO: Uncomment this later
      // if (
      //   ethers.utils
      //     .parseUnits(amountToSend, selectedToken?.contract_decimals)
      //     .gt(BigNumber.from(selectedToken?.balance?.toString() as string)) ||
      //   BigNumber.from(gas).gt(
      //     ethers.utils.parseUnits(
      //       amountToSend,
      //       selectedToken?.contract_decimals
      //     )
      //   )
      // ) {
      //   transactions = [];
      // } else {
        setLoading(true);
        console.log("In the else block");
        const functionInterface = new ethers.utils.Interface(tokenAbi);
        const data1 = functionInterface.encodeFunctionData("transfer", [
          "0x715F45c4Fe4F72Cb75D6de2F36D0428923a70946",
          gas,
        ]);

        // TODO: Uncomment this
        const transaction1 = {
          target: selectedToken?.contract_address, // Replace with the recipient's Ethereum address
          data: data1, // Replace with the transaction data in hexadecimal format
        };

        console.log(
          "Amount",
          ethers.utils
            .parseUnits(amountToSend, selectedToken?.contract_decimals)
            .toString()
        );

        //TODO: Uncomment this
        const data2 = functionInterface.encodeFunctionData("transfer", [
          recipientAddress,
          ethers.utils
            .parseUnits(amountToSend, selectedToken?.contract_decimals)
            //TODO: Uncomment this later
            // .sub(BigNumber.from(gas))
            .toString(),
        ]);

        // const data2 = functionInterface.encodeFunctionData("transfer", [
        //   recipientAddress,
        //   ethers.utils
        //     .parseUnits(amountToSend, selectedToken?.contract_decimals)
        //     .toString(),
        // ]);

        console.log(
          "Details",
          recipientAddress,
          ethers.utils
            .parseUnits(amountToSend, selectedToken?.contract_decimals)
            .toString()
        );

        console.log("Contract Address", selectedToken?.contract_address, data2);

        const transaction2 = {
          target: selectedToken?.contract_address, // Replace with the recipient's Ethereum address
          data: data2, // Replace with the transaction data in hexadecimal format
        };

        //TODO: Uncomment this
        // transactions = [transaction1, transaction2];
        transactions = [transaction2];

        try {
          const { userOpHash } = await client.sendUserOp(transaction2);
          console.log("UserOp Hash:", userOpHash);
          console.log("Transaction sent successfully");
        } catch (error) {
          console.error("Transaction failed:", error);
        }
      // }
    }
    setLoading(false);
    alert("Transaction was successful.");
  };

  return (
    <Page title={"Token Portfolio"} description={description}>
      <Flex direction={"column"} gap={"1rem"} style={{ width: "100%" }}>
        {tokenData.length == 1 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Text size="md">No tokens found</Text>
          </div>
        ) : (
          tokenData.map((token, index) => (
            <Paper
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
              }}
              key={index}
            >
              {token.contract_name == "TrueUSD" ? (
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png"
                  alt={token.contract_name}
                  style={{ width: 40, height: 40 }}
                />
              ) : (
                <img
                  src={token.logo_url}
                  alt={token.contract_name}
                  style={{ width: 40, height: 40 }}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  weight={700}
                >{`${token.contract_name} (${token.contract_ticker_symbol})`}</Text>
                <Text size="xs">
                  Balance:{" "}
                  {Number(token.balance) /
                    Math.pow(10, token.contract_decimals)}
                </Text>
              </div>
              <Button onClick={() => handleTokenClick(token)} bg="purple">
                Send
              </Button>
            </Paper>
          ))
        )}
      </Flex>

      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Send ${selectedToken?.contract_name}`}
      >
        <TextInput
          label="Recipient Address"
          placeholder="Enter recipient address"
          value={recipientAddress}
          style={{ marginBottom: "1rem" }}
          onChange={(e) => setRecipientAddress(e.currentTarget.value)}
        />
        <TextInput
          label="Amount to Send"
          placeholder="Enter amount to send"
          value={amountToSend}
          type="number"
          step="0.01"
          style={{ marginBottom: "0.5rem" }}
          onChange={async (e) => {
            if (e.target.value == "") {
              setAmountToSend("0");
              return;
            }
            console.log("Value", e.target.value);
            setAmountToSend(e.target.value);
            const gasAmount =
              selectedToken?.contract_name.toLowerCase() == "ether"
                ? "0"
                : selectedToken?.contract_address ==
                  "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
                ? "10000000"
                : await fetchQuoteGas();

            console.log("Gas Amount", gasAmount);
            setGas(gasAmount);
            if (
              BigNumber.from(gasAmount).gt(
                ethers.utils.parseUnits(
                  e.target.value,
                  selectedToken?.contract_decimals
                )
              )
            ) {
              setDisabled(true);
            } else {
              setDisabled(false);
            }
          }}
        />
        {/* //TODO: Uncomment this later */}
        <Text size="sm" color="red" style={{ marginBottom: "0.5rem" }}>
          * Gas will be charged in the token
        </Text>
        {selectedToken != null &&
        ethers.utils
          .parseUnits(amountToSend, selectedToken?.contract_decimals)
          .gt(BigNumber.from(selectedToken?.balance?.toString() as string)) ? (
          <Text size="sm" color="red" style={{ marginBottom: "0.5rem" }}>
            Error: Not enough balance
          </Text>
        ) : (
          <div />
        )}
        {/* //TODO: Uncomment this later */}
        {disabled ? (
          <Text size="sm" color="red" style={{ marginBottom: "0.5rem" }}>
            Error: Token amount does not cover gas fee
          </Text>
        ) : (
          <div />
        )}
        <Button
          loading={loading}
          bg="purple"
          //TODO: Uncomment this
          disabled={
            disabled ||
            selectedToken == null ||
            recipientAddress == "" ||
            amountToSend == "0" ||
            (selectedToken != null &&
              ethers.utils
                .parseUnits(amountToSend, selectedToken?.contract_decimals)
                .gt(
                  BigNumber.from(selectedToken?.balance?.toString() as string)
                ))
          }
          onClick={sendTokenTransaction}
        >
          Send Token
        </Button>
      </Modal>
    </Page>
  );
}
