// @flow
import React from "react";
import PropTypes from "prop-types";
import { SdkContext } from "../context/SdkContext";

class TelegramLoginButton extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { buttonSize, cornerRadius, usePic, dataOnauth, dataAuthUrl, lang } =
      this.props;
    window.TelegramLoginWidget = {
      dataOnauth: (user) => dataOnauth(user),
    };

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    script.setAttribute("data-telegram-login", "Oxgasless_test_bot");
    // script.setAttribute("data-size", buttonSize);
    // if (cornerRadius !== undefined) {
    //   script.setAttribute("data-radius", cornerRadius);
    // }
    script.setAttribute("data-request-access", "write");
    // script.setAttribute("data-userpic", usePic);
    // script.setAttribute("data-lang", lang);
    if (dataAuthUrl !== undefined) {
      console.log("If was called");
      script.setAttribute(
        "data-auth-url",
        "https://api.0xgasless.com/telegram-auth/auth/telegram/callback"
      );
    } else {
      console.log("If was called");
      script.setAttribute(
        "data-onauth",
        "TelegramLoginWidget.dataOnauth(user)"
      );
    }
    script.async = true;
    this.instance.appendChild(script);
  }

  render() {
    return (
      <SdkContext.Consumer>
        {({ setIsConnected }) => (
          <div
            className={this.props.className}
            ref={(component) => {
              this.instance = component;
              this.context = { setIsConnected }; // Save context in an instance variable
            }}
          ></div>
        )}
      </SdkContext.Consumer>
    );
  }
}

TelegramLoginButton.propTypes = {
  botName: PropTypes.string.isRequired,
  dataOnauth: PropTypes.func,
  buttonSize: PropTypes.oneOf(["large", "medium", "small"]),
  cornerRadius: PropTypes.number,
  requestAccess: PropTypes.string,
  usePic: PropTypes.bool,
  lang: PropTypes.string,
  widgetVersion: PropTypes.number,
};

TelegramLoginButton.defaultProps = {
  botName: "samplebot",
  dataOnauth: () => undefined,
  buttonSize: "large",
  requestAccess: "write",
  usePic: true,
  lang: "en",
  widgetVersion: 9,
};

export default TelegramLoginButton;
