import { Box, Tab } from "@mui/material";
import React, {useState} from "react";
import ExchangeDetails from "./Components/ExchangeDetails";
import ConfirmDetails from "./Components/ConfirmDetails";
import CompleteExchange from "./Components/CompleteExchange";
import checkEnabled from "./resources/assets/images/checked-enabled.svg"
import checkDisabled from "./resources/assets/images/checked-disabled.svg"

const Bridge = () => {

  // const ExchangeList = [
  //   {
  //     section: 1,
  //     component: <ExchangeDetails changeVal={screenChanger}/>,
  //   },
  //   {
  //     section: 2,
  //     component: <ConfirmDetails />,
  //   },
  //   {
  //     section: 3,
  //     component: <CompleteExchange />,
  //   },
  // ]

  const [displayComponent, setDisplayComponent] = useState(0);

  const screenChanger = (val) => {
    setDisplayComponent(val)
  }


  console.log(displayComponent)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          height: "4rem",
          width: "100%",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          m: '2rem 0'
        }}
      >
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <img src={displayComponent === 0 ? checkEnabled : checkDisabled} style={{height: '2rem'}}/>
            <Box sx={{p: '0 5px', opacity: displayComponent === 0 ? '1':'0.4', fontSize: '12px'}}>Enter Exchange Details</Box>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <img src={displayComponent === 1 ? checkEnabled : checkDisabled} style={{height: '2rem'}}/>
            <Box sx={{p: '0 5px', opacity: displayComponent === 1 ? '1':'0.4', fontSize: '12px'}}>Confirm the Exchange</Box>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <img src={displayComponent === 2 ? checkEnabled : checkDisabled} style={{height: '2rem'}}/>
            <Box sx={{p: '0 5px', opacity: displayComponent === 2 ? '1':'0.4', fontSize: '12px'}}>Complete the Exchange</Box>
        </Box>
      </Box>
      {displayComponent === 0 && <ExchangeDetails changeVal={screenChanger}/>}
      {displayComponent === 1 && <ConfirmDetails changeVal={screenChanger}/>}
      {displayComponent === 2 && <CompleteExchange changeVal={screenChanger}/>}
    </Box>
  );
};

export default Bridge;
