import React, { useState, createContext, useContext } from "react";
import AA0xGasless from "@0xgasless/0xgasless-aa-sdk";
import { ethers } from "ethers";
import { Web3Auth } from "@web3auth/modal";
import { ParticleNetwork, WalletEntryPosition } from "@particle-network/auth";
import { ParticleProvider } from "@particle-network/provider";
import { Ethereum } from "@particle-network/chains";
import { CHAIN_NAMESPACES, IProvider } from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import axios from "axios";
import { AA0xGaslessTG } from "@0xgasless/0xgasless-aa-sdk";

export const SdkContext = createContext();

export const SdkProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [smartWalletAddress, setSmartWalletAddress] = useState(null);
  const [provider, setProvider] = useState(null);
  const [client, setClient] = useState(null);
  const chains = {
    Ethereum: 1,
    Fantom: 250,
    Moonbeam: 1284,
  };
  const [activeChain, setActiveChain] = useState("Ethereum");
  const [chainId, setChainId] = useState(chains["Ethereum"]);
  const [isOpen, setIsOpen] = useState(false);

  const apiKey =
    "0xGS-38447a1b1dd5f056ff9ec7893cac3ffca5732743bae32216031e2f0734dc9e1a";

  const clientSample = new AA0xGasless(apiKey);

  const handleSelectChain = async (chainName) => {
    setActiveChain(chainName);
    setChainId(chains[chainName]);
    const options = {
      chainId: chains[chainName],
      rpcUrl:
        chains[chainName] === 1
          ? "https://eth-mainnet.g.alchemy.com/v2/bOg2BRsD466eVUYnfiZ0w9g0X_rWTbKL"
          : chains[chainName] === 250
          ? "https://fantom-pokt.nodies.app"
          : chains[chainName] === 1284
          ? "https://moonbeam.publicnode.com"
          : "https://eth-mainnet.g.alchemy.com/v2/bOg2BRsD466eVUYnfiZ0w9g0X_rWTbKL",
      isSponsoredTrx: chains[chainName] === 1 ? true : false,
      paymaster: chains[chainName] === 1 ? "PIMLICO" : "",
      paymasterEndpoint:
        chains[chainName] === 1
          ? "https://api.pimlico.io/v2/ethereum/rpc?apikey=8e0592e6-be7b-42fa-95b3-a6e9ff0121cf"
          : "",
      // bundlerEndpoint:
      //   "https://api.pimlico.io/v1/ethereum/rpc?apikey=8e0592e6-be7b-42fa-95b3-a6e9ff0121cf",
    };

    const address = await clientSample.init(provider, options);
    setSmartWalletAddress(address.smartAccountAddress);
    console.log(address);

    setIsConnected(true);
    setIsOpen(false);
  };

  const connect = async () => {
    try {
      //TODO: Change API Key
      setClient(clientSample);

      console.log(clientSample);

      const chainConfig = {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x1",
        rpcTarget:
          "https://eth-mainnet.g.alchemy.com/v2/bOg2BRsD466eVUYnfiZ0w9g0X_rWTbKL",
        displayName: "Ethereum Mainnet",
        blockExplorer: "https://goerli.etherscan.io",
        ticker: "ETH",
        tickerName: "Ethereum",
      };

      const web3auth = new Web3Auth({
        clientId:
          "BAacD-mG7kr5fLjYkAX3xy0UDS3BfMJZpAz4HAeqW6vmjYB6UL9UfoxebWS5AEiaq1kYYi0KWQFw6RPRPQMZAI8", // Get your Client ID from the Web3Auth Dashboard
        web3AuthNetwork: "sapphire_mainnet", // Web3Auth Network
        chainConfig,
        uiConfig: {
          appName: "0xgasless",
          mode: "dark", // light, dark or auto
          loginMethodsOrder: ["apple", "google", "twitter"],
          logoLight: "https://web3auth.io/images/web3auth-logo.svg",
          logoDark: "https://web3auth.io/images/web3auth-logo---Dark.svg",
          defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
          loginGridCol: 3,
          primaryButton: "socialLogin", // "externalLogin" | "socialLogin" | "emailLogin"
        },
      });

      console.log(web3auth);
      await web3auth.initModal();

      const web3authProvider = await web3auth.connect();
      const ethersProvider = new ethers.providers.Web3Provider(
        web3authProvider
      );
      
      setProvider(ethersProvider);

      // const privateKeyProvider = new EthereumPrivateKeyProvider({
      //   config: { chainConfig },
      // });

      // const openloginAdapter = new OpenloginAdapter({
      //   privateKeyProvider,
      //   adapterSettings: {
      //     uxMode: "redirect",
      //     loginConfig: {
      //       jwt: {
      //         verifier: "0xgasless", // Pass the Verifier name here
      //         typeOfLogin: "twitter", // Pass on the login provider of the verifier you've created
      //         clientId: "KPByCXoCliM1rI6a2aFF3w0oiVAdVu8U", // Pass on the Auth0 `Client ID` here
      //       },
      //     },
      //   },
      // });
      // web3auth.configureAdapter(openloginAdapter);

      // // Initialize
      // await web3auth.init();

      // // Login with Twitter / X
      // await web3auth.connectTo({
      //   loginProvider: "twitter",
      //   extraLoginOptions: {
      //     domain: "https://dev-tqb6kg7j37rzdbkb.us.auth0.com/api/v2", // Pass on the Auth0 `Domain` here
      //     verifierIdField: "sub", // Pass on the field name of the `sub` field in the JWT
      //     connection: "twitter", // Use this to skip Auth0 Modal for Twitter / X login
      //   },
      // });

      //TODO: change chain id to Ethereum
      const options = {
        chainId: chainId,
        rpcUrl:
          "https://eth-mainnet.g.alchemy.com/v2/bOg2BRsD466eVUYnfiZ0w9g0X_rWTbKL",
        isSponsoredTrx: true,
        paymaster: "PIMLICO",
        paymasterEndpoint:
          "https://api.pimlico.io/v2/ethereum/rpc?apikey=8e0592e6-be7b-42fa-95b3-a6e9ff0121cf",
        // bundlerEndpoint:
        //   "https://api.pimlico.io/v1/ethereum/rpc?apikey=8e0592e6-be7b-42fa-95b3-a6e9ff0121cf",
      };

      const address = await clientSample.init(ethersProvider, options);
      setSmartWalletAddress(address.smartAccountAddress);
      console.log(address);

      setIsConnected(true);
    } catch (error) {
      console.log(error);
    }
  };

  async function callApi(id, firstName, lastName, username) {
    try {
      // Constructing the URL with query parameters
      const url = `https://api.0xgasless.com/telegram-auth/auth/telegram/callback`;
      const params = {
        id: id,
        first_name: firstName,
        last_name: lastName,
        username: username,
      };

      // Making the GET request
      const response = await axios.get(url, { params });

      // Handling the response
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      // Error handling
      console.error("Error:", error);
    }
  }

  const handleTelegramResponse = async (response) => {
    console.log(response);
    const data = await callApi(
      response.id,
      response.first_name,
      "",
      response.username
    );
    const apiKey =
      "0xGS-38447a1b1dd5f056ff9ec7893cac3ffca5732743bae32216031e2f0734dc9e1a";

    //       const tgOptions: TelegramClientOptions = {
    //         telegramUserId: "6732034940",
    //         walletAddress: "0x78695de2c38aca313c7acb26c2d28dc959809a52",
    //         chainId: 1, //arb goerli
    //         rpcUrl: "https://eth-mainnet.g.alchemy.com/v2/bOg2BRsD466eVUYnfiZ0w9g0X_rWTbKL",//process.env.ARB_GOERLI_RPC,
    //         isSponsoredTrx: true,
    //         paymaster: 'PIMLICO',
    //         paymasterEndpoint: "https://api.pimlico.io/v2/ethereum/rpc?apikey=8e0592e6-be7b-42fa-95b3-a6e9ff0121cf",
    //     }
    // const sdk = new AA0xGaslessTG(apiKey);
    //         const address = await sdk.init(provider,tgOptions);
    //         console.log("smart account address", address);

    const options = {
      chainId: 1, // Required: Replace with the desired chain ID
      telegramUserId: data.id, // Required: Specify the Telegram user ID
      walletAddress: data.address, // Required: Provide the wallet address
      // rpcUrl: "https://ethereum-goerli.publicnode.com",
      rpcUrl:
        "https://eth-mainnet.g.alchemy.com/v2/bOg2BRsD466eVUYnfiZ0w9g0X_rWTbKL", // Optional: Specify your custom RPC URL if needed
      isSponsoredTrx: true, // Optional: Set to true if using sponsored transactions
      paymasterEndpoint:
        "https://api.pimlico.io/v2/ethereum/rpc?apikey=8e0592e6-be7b-42fa-95b3-a6e9ff0121cf", // Optional: Specify your custom paymaster endpoint
      paymaster: "PIMLICO", // Optional: Choose a paymaster type ("PIMLICO" or "STACKUP")
      bundlerEndpoint:
        "https://api.pimlico.io/v1/ethereum/rpc?apikey=8e0592e6-be7b-42fa-95b3-a6e9ff0121cf",
    };

    console.log("Options", options);

    const client = new AA0xGaslessTG(apiKey);

    const provider2 = new ethers.providers.JsonRpcProvider(
      // "https://eth-mainnet.g.alchemy.com/v2/bOg2BRsD466eVUYnfiZ0w9g0X_rWTbKL"
      "https://eth-mainnet.g.alchemy.com/v2/bOg2BRsD466eVUYnfiZ0w9g0X_rWTbKL"
    );

    const address = await client.init(provider2, options);
    console.log("Smart Wallet Address", address);

    setSmartWalletAddress(address.smartAccountAddress);
    setIsConnected(true);
  };

  return (
    <SdkContext.Provider
      value={{
        isConnected,
        setIsConnected,
        connect,
        smartWalletAddress,
        provider,
        client,
        handleTelegramResponse,
        setIsOpen,
        handleSelectChain,
        chainId,
        chains,
        activeChain,
        isOpen,
      }}
    >
      {children}
    </SdkContext.Provider>
  );
};

// Step 3: Export the Context (if needed for useContext)
export const useSdkContext = () => useContext(SdkContext);
