import { Dashboard } from "./Dashboard";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import { SponsoredGasExample } from "./examples/SponsoredGasExample";
import { BatchExample } from "./examples/BatchExample";
import AASwap from "./AASwap";
import "./App.css";
import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bridge from "./Bridge";
import Migration from "./Migration";
import Onramp from "./Onramp";
import { useSdkContext } from "./context/SdkContext";

function App() {
  const { chainId } = useSdkContext();

  console.log("chainId", chainId);

  const links = [
    { path: "/Portfolio", label: "0xgas Pay", element: <BatchExample /> },
    // {
    //   path: "/nfts",
    //   label: "NFTs \n(Coming soon)",
    //   element: <BatchExample />,
    // },
    // Other links here...
    ...(chainId == 1
      ? [
          {
            path: "/swap",
            label: "Swap",
            element: <AASwap />,
          },
          {
            path: "/bridge",
            label: "Bridge",
            element: <Bridge />,
          },
        ]
      : []),
    // {
    //   path: "/migration",
    //   label: "Migration",
    //   element: <Migration />,
    // },
    // {
    //   path: "/onramp",
    //   label: "Onramp",
    //   element: <Onramp />,
    // },
    //TODO: Add later
    // Other links here...
  ];

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard children={<Outlet />} links={links} />,
      errorElement: <Navigate to={"/"} replace />,
      children: [
        {
          index: true,
          element: <Navigate to={links[0].path} replace />,
        },
        ...links,
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;
